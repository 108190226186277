import React, { useRef } from "react";
import { StyledMobileNav, StyledMobileNavWrapper } from "./MobileNav.styled";
import { Modal, Nav, ContactDetails, Logo } from "../index";
import { useClickAway } from "ahooks";

const MobileNav = (props) => {
  const {
    phone,
    email,
    facebook,
    instagram,
    phoneIcon,
    emailIcon,
    facebookIcon,
    instagramIcon,
    iconVariant,
    labelVariant,
    social,
    iconSize,
    navMenu,
    open,
    modalVariant,
    setOpen,
  } = props;

  const modalRef = useRef();

  const isClickedOutside = useClickAway(() => {
    setOpen(false);
  }, modalRef);

  return (
    <div ref={modalRef}>
      <Modal open={open} setOpen={setOpen} variant={modalVariant}>
        <StyledMobileNav>
          <StyledMobileNavWrapper>
            <Logo variant="secondary" />
            <ContactDetails
              phone={phone}
              email={email}
              phoneIcon={phoneIcon}
              emailIcon={emailIcon}
              iconVariant={iconVariant}
              labelVariant={iconVariant}
            />
            <Nav navMenu={navMenu} variant="mobileNav" />
            <ContactDetails
              facebook={facebook}
              instagram={instagram}
              facebookIcon={facebookIcon}
              instagramIcon={instagramIcon}
              iconVariant={iconVariant}
              labelVariant={labelVariant}
              social={social}
              iconSize={iconSize}
            />
          </StyledMobileNavWrapper>
        </StyledMobileNav>
      </Modal>
    </div>
  );
};

export default MobileNav;
