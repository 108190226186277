import styled from 'styled-components'
import { Cross as Hamburger } from 'hamburger-react'
import { layout } from 'styled-system';

export const StyledHamburger = styled(Hamburger)`
${layout}

`

StyledHamburger.defaultProps = {

}


