import React from "react";
import { StyledTyping, StyledTypeWriter } from "./Typing.styled";
import { Headline } from "../";
import Typewriter from "typewriter-effect";

export default function Typing({ title, typed }) {
  return (
    <>
      {title && (
        <StyledTyping>
          <Headline variant="ternary" mb="5" h={3} type="h3" heading={title} />
          <StyledTypeWriter>
            <Typewriter
              options={{
                strings: typed,
                autoStart: true,
                loop: true,
              }}
            />
          </StyledTypeWriter>
        </StyledTyping>
      )}
    </>
  );
}
