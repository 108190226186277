import styled from "styled-components";
import { variant, space, color, layout, flexbox } from "styled-system";

export const StyledLabel = styled.li(
  space,
  color,
  layout,
  flexbox,

  variant({
    variants: {
      primary: {
        "& a, & span": {
          color: "footerColorPrimary",
          "&:hover": {
            color: "footerHoverColorPrimary",
          },
        },
      },
      secondary: {
        "& a, & span": {
          color: "footerColorSecondary",
          "&:hover": {
            color: "footerHoverColorSecondary",
          },
        },
      },
      ternary: {
        "& a, & span": {
          color: "footerColorTernary",
          "&:hover": {
            color: "footerHoverColorTernary",
          },
        },
      },
    },
  })
);

StyledLabel.defaultProps = {
  display: "flex",
  alignItems: "center",
  mb:0
};

export const StyledLabels = styled.ul`
  ${space}
  ${color}
  ${layout}
  ${flexbox}

 @media(max-width: ${({theme}) => theme.breakpoints[2]}){
   display: flex;
   flex-direction: column;
   align-items: center;
 }
`;

StyledLabels.defaultProps = {

}

