import styled from "styled-components";
import { typography, space, color, flexbox, variant } from 'styled-system'
import BasePortableText from "@sanity/block-content-to-react";



export const StyledText = styled(BasePortableText)`
${space}
${typography}
${color}
${flexbox}

${variant({
  variants: {
    primary: {
      color: "primaryText"
    },
    secondary: {
      color: "secondaryText"
    },
    ternary: {
      color: "ternaryText"
    }
  }
})}

  max-width: 60ch;
  
`;

StyledText.defaultProps = {
  color: "text",
  fontSize: { 
    xs: "medium",
    md: "large", 
    lg: "large" 
  },

  mb:{
    lg:4,
    md:4,
    xs:3
  }

}
