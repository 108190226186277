import React from "react";
import { Col, Headline } from "../index";
import { StyledList, StyledListItems, StyledListItem, StyledListItemTitle } from "./List.Styled";

export default function List({ items }) {
  return (
    <StyledList>
      {items &&
        items.map((item) => {
          return (
            <Col key={item._key}>
              <Headline h={3} type="h5" heading={item.subtitle} mb="4" />
              <StyledListItems>
                {item.items.map((item) => {
                  return (
                    <StyledListItem key={item._key}>
                      <StyledListItemTitle>{item.title}</StyledListItemTitle>
                    </StyledListItem>
                  );
                })}
              </StyledListItems>
            </Col>
          );
        })}
    </StyledList>
  );
}
