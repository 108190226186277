import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { window } from "browser-monads";
import { StyledNavItems, StyledNavItem } from "./Nav.styled";

const filterNavItems = (items) => {
  let mainNavItems = [];

  items.forEach((navMenuItem) => {
    const { SubNav, landingPageRoute, route, title, _key, link } = navMenuItem;
    let mainLink = route || link || "#";
    if (landingPageRoute && landingPageRoute.slug && landingPageRoute.slug.current) {
      mainLink = "/" + landingPageRoute.slug.current + "/";
    }
    mainNavItems.push({ title, _key, mainLink, SubNav });
  });

  return mainNavItems;
};

const NavItems = ({ navMenu, variant }) => {
  const { items } = navMenu;
  const mainNavItems = filterNavItems(items);

  return (
    <>
      {mainNavItems &&
        mainNavItems.map((navItem) => {
          const { title, _key, mainLink } = navItem;
          const currentLocation = window.location.pathname;

          return (
            <StyledNavItem
              variant={variant}
              key={_key}
              className={mainLink == currentLocation ? "active-link-parent" : null}
            >
              <GatsbyLink to={mainLink} data-title={title} activeClassName="active-link">
                <span>{title}</span>
              </GatsbyLink>
            </StyledNavItem>
          );
        })}
    </>
  );
};

const Nav = ({ navMenu, variant }) => {
  return (
    <StyledNavItems variant={variant}>
      <NavItems variant={variant} navMenu={navMenu} />
    </StyledNavItems>
  );
};

export default Nav;
