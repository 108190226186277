import React from "react";
import { StyledLabel, StyledLabels } from "./FooterNav.styled";
import { Col, Headline, Icon } from "../index";
import { Link } from "gatsby";

const FooterLabel = ({ Items, title }) => {

  return (
    <>
      <Headline h={4} type="h5" heading={title} />
      <StyledLabels>
        {Items.map((item) => {
          const { link, externalLink, label, _key, icon } = item;

          if (link && externalLink) {
            return (
              <StyledLabel variant="ternary" key={_key}>
                {icon && <Icon mr="3" variant="secondary" icon={icon} />}
                <a href={link}>{label}</a>
              </StyledLabel>
            );
          }

          if (link && !externalLink) {
            return (
              <StyledLabel variant="ternary" key={_key}>
                {icon && <Icon mr="3" variant="secondary" icon={icon} />}
                <Link to={link} data-title={label}>{label}</Link>
              </StyledLabel>
            );
          }

          return (
            <StyledLabel variant="ternary" key={_key}>
              {icon && <Icon mr="3" variant="secondary" icon={icon} />}
              <span>{label}</span>
            </StyledLabel>
          );
        })}
      </StyledLabels>
    </>
  );
};

const MainNav = (props) => {
  const { navMenu } = props;

  return (
    <>
      <Headline h={4} type="h5" heading="Onze Diensten" mb="3" />
      <StyledLabels>
        {navMenu &&
          navMenu.map((item) => {
            const { _key, title, landingPageRoute } = item;
            const link = "/" + landingPageRoute.slug.current + "/"
            return (
              <StyledLabel variant="ternary" key={_key}>
                <Link to={link} data-title={title}>{title}</Link>
              </StyledLabel>
            );
          })}
      </StyledLabels>
    </>
  );
};

const FooterNav = ({ footerMenu, navMenu }) => {
  
  const { columns } = footerMenu;
  return (
    <>
      {columns &&
        columns.map((column) => {
          const { Items } = column;
          return (
            <Col key={column._key}>
              <FooterLabel title={column.title} Items={Items} />
            </Col>
          );
        })}
      <Col variant="footer">
        <MainNav navMenu={navMenu.items} />
      </Col>
    </>
  );
};

export default FooterNav;
