import { motion } from "framer-motion";
import styled from "styled-components";
import { space, color, border, layout, flexbox, typography, shadow, variant } from "styled-system";
import { themeGet } from "@styled-system/theme-get";

export const FaqHeader = styled.div`
  ${layout};
  ${flexbox};
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const FaqQuestion = styled(motion.h3)`
  ${space};
  ${typography};
  ${color};
  ${({isOpen}) => variant({
    variants: {
      primary: {
        color: "primaryAccordionQuestion",
      },
      secondary: {
        color: isOpen ? "primaryAccordionQuestion" : "secondaryAccordionQuestion",
        "li:hover &": {
          color: "primaryAccordionQuestion",
        },
      },
      ternary: {
        color: "ternaryAccordionQuestion",
      },
    },
  })}
  max-width: 40ch;
  font-weight: 700;
  font-style: italic;
  user-select: none;
  transition: all 0.4s ease;
`;

FaqQuestion.defaultProps = {
  mb: 3,
  fontSize: {
    lg: "h5",
    xs: "1rem",
  },
};

export const FaqAnswer = styled(motion.div).attrs(({ theme }) => {})`
  ${space};
  width: 100%;
  border-radius: 10px;
  user-select: none;

  & div {
    max-width: unset !important;
    padding-bottom: 16px;
  }

  & p:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:last-child {
    padding-bottom: 0px;
  }
`;

FaqAnswer.defaultProps = {};

export const FaqItem = styled(motion.li).attrs(({ theme }) => ({}))`
  ${border};
  ${color};
  ${space};
  overflow: hidden;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid ${themeGet("colors.white50", "#1A9787")};
  }

  & h3 {
    padding: 16px 0;
  }

  & h3 {
    margin-bottom: 0;
    padding-top: 16px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  /* & p {
      margin-bottom: 1.2rem;
      &:first-child {
        padding-top: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  } */
`;

FaqItem.defaultProps = {
  m: 0,
  p: 0,
};

export const FaqItems = styled(motion.ul)`
  ${space}
  ${border}
  ${shadow}
  ${color}
  border: 2px solid;

  ${variant({
    variants: {
      primary: {
        borderColor: "primaryBorder",
        color: "primaryIcon",
        "& a": {
          color: "primaryIcon",
        },
      },
    },
  })}
  display: flex;
  flex-direction: column;
`;

FaqItems.defaultProps = {
  p: 4,
  borderRadius: "16px",
};

const iconPlusVariants = {
  closed: {
    opacity: 1,
  },
  open: {
    opacity: 1,
    rotate: 360,
    transition: {
      type: "tween",
    },
  },
  close: {
    opacity: 1,
    rotate: 360,
  },
};

const iconMinVariants = {
  closed: {
    opacity: 1,
  },
  open: {
    opacity: 1,
    rotate: -360,
    transition: {
      type: "tween",
    },
  },
  close: {
    opacity: 1,
    rotate: 360,
  },
};

export const IconPlusWrapper = styled(motion.span).attrs(({ isOpen }) => ({
  variants: iconPlusVariants,
  initial: "closed",
  animate: "open",
  exit: "close",
}))``;

export const IconMinWrapper = styled(motion.span).attrs(({ isOpen }) => ({
  variants: iconMinVariants,
  initial: "closed",
  animate: "open",
  exit: "close",
}))``;
