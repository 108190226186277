import React from "react";
import { StyledHeadline } from "./Headline.styled";

export default function Headline(props) {
  const { h = 1, type, split = false, heading, variant = "primary"} = props;
  let headline = heading && split ? heading.split(/(?=[p])/g) : heading;

  if (split) {
    return (
      <>
        {heading && (
          <StyledHeadline  {...props} variant={variant}  type={type} as={`h${h}`} h={h} split={split}>
            {headline.map((k, i) => {
              return (
                <span key={i}>
                  {headline[i]}
                  <br />
                </span>
              );
            })}
          </StyledHeadline>
        )}
      </>
    );
  }

  return (
    <>
      {heading && (
        <StyledHeadline {...props} variant={variant}  type={type} as={`h${h}`} h={h}>
          {headline}
        </StyledHeadline>
      )}
    </>
  );
}
