const React = require("react");
const Layout = require("./src/containers/layout").default;
const { Provider } = require("jotai");
const { ThemeProvider } = require("styled-components");
const { GlobalStyles, theme } = require("./src/styles/jss/Theme/index");

exports.wrapRootElement = ({ element }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Provider>{element}</Provider>
      </ThemeProvider>
    </>
  );
};

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      <GlobalStyles />
      <Layout {...props}>{element}</Layout>
    </>
  );
};
