import React from "react";
import { StyledWrapper } from "./Wrapper.styled";
import Divider from "../Dividers/Divider";

const Wrapper = (props) => {
  return (
    <StyledWrapper  {...props}>
      {props.children}
    </StyledWrapper>
  );
};

export default Wrapper;


{/* <Divider type={props.type} /> */}
