import styled from "styled-components";
import { space, layout, flexbox, typography, color, border } from "styled-system";

export const StyledList = styled.div`
  ${flexbox}
  ${layout}
${space}

justify-content: space-around;

  /* @media(min-width: ${({ theme }) => theme.breakpoints[0]}){
  flex-direction:column;
}

@media(min-width: ${({ theme }) => theme.breakpoints[2]}){
  flex-direction:row;
  flex-grow: 1;
}

@media(min-width: ${({ theme }) => theme.breakpoints[4]}){
  flex-direction:column;
  flex-grow: 1;
} */
`;

StyledList.defaultProps = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
};

export const StyledListItems = styled.ul`
  ${layout}
  ${flexbox}
${space}
`;

StyledListItems.defaultProps = {
  display: "flex",
  flexWrap: "wrap",
  flexDirection: {
    xs: "column",
    md: "row",
  },
  mb: 3,
};

export const StyledListItem = styled.li`
  ${space}
  ${typography}
`;

StyledListItem.defaultProps = {
  mb: 2,
  mr: 2,
  fontSize: {
    xs: "medium",
    md: "large",
  },
};

export const StyledListItemTitle = styled.span`
  ${color}
  ${space}
${border}

display: inline-block;
`;

StyledListItemTitle.defaultProps = {
  bg: {
    xs: "transparent",
    md: "primary",
  },
  color: {
    xs: "#333",
    md: "#fff",
  },
  py: {
    xs: 0,
    md: 2
  },
  px: {
    xs: 0,
    md: 3 
  },
  borderRadius: "99999px",
};
