import styled from "styled-components";
import { motion } from "framer-motion";
import { layout, space, color, variant } from "styled-system";
import { Link } from "react-scroll";

const selectStyle = (type, theme) => {
  const styles = {
    fill: {
      background: theme.colors.primaryBtnBg,
      border: `1px solid ${theme.colors.primaryBtnBorder}`,
      span: {
        color: `${theme.colors.primaryBtnFont}`,
      },

      ":hover": {
        borderColor: theme.colors.primaryBtnBorderHover,
        background: "transparent",
        span: {
          color: theme.colors.primaryBtnFontHover,
        },
      },
    },
    outline: {
      background: `${theme.colors.primaryBtnBg}`,
      border: `1px solid ${theme.colors.secondaryBtnBorder}`,

      span: {
        color: `${theme.colors.secondaryBtnFont}`,
      },

      ":hover": {
        borderColor: theme.colors.secondaryBtnBorderHover,
        background: theme.colors.secondaryBtnBgHover,
        span: {
          color: theme.colors.secondaryBtnFontHover,
        },
      },
    },
  };

  return styles[type];
};

export const BtnWrapper = styled(motion.div).attrs(() => ({
  initial: "start",
  animate: "hover",
}))`
display: flex;
align-items: flex-start;
`;

export const SmoothLink = styled(Link).attrs(() => ({}))`
  ${layout}
  ${space}
  ${color}
  ${variant({
    variants: {
      primaryFill: {
        bg: "primaryBtnBg",
        border: 1,
        borderColor: "primaryBtnBorder",
        ":hover": {
          borderColor: "primaryBtnBorderHover",
          background: "transparent",
          span: {
            color: "primaryBtnFontHover",
          },
        },
      },
    },
  })}
  ${({ variant, theme }) => selectStyle(variant, theme)}
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  outline: none;
  transition: background 1s;
  border-radius: 9999px;
  align-items: center;

  & span {
    font-size: 16px;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    display: flex;
  }
`;

export const StyledBtn = styled(motion.button).attrs(({ hideMobile }) => ({
  initial: "start",
  whileHover: "hover",
  animate: "end",
}))`
  ${layout}
  ${space}
  ${color}
  ${variant({
    variants: {
      primaryFill: {
        bg: "primaryBtnBg",
        border: 1,
        borderColor: "primaryBtnBorder",
        ":hover": {
          borderColor: "primaryBtnBorderHover",
          background: "transparent",
          span: {
            color: "primaryBtnFontHover",
          },
        },
      },
    },
  })}
  ${({ variant, theme }) => selectStyle(variant, theme)}
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  outline: none;
  transition: background 1s;
  border-radius: 9999px;
  align-items: center;

  & span {
    font-size: 16px;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    display: flex;
  }
`;

export const StyledTitle = styled(motion.span)`
  white-space: nowrap;
`;

export const StyledLink = styled(StyledBtn).attrs(() => ({
  as: motion.div,
}))`
  ${layout}
  ${({ variant, theme }) => selectStyle(variant, theme)}
  & a {
    display: flex;
    align-items: center;

    & span {
      display: flex;
    }
  }
`;

export const StyledExternalLink = styled(StyledBtn).attrs(() => ({
  as: motion.a,
}))`
  ${layout}
  ${({ variant, theme }) => selectStyle(variant, theme)}
  display: inline-flex;
`;
