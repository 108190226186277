import styled from "styled-components";
import { color, variant, position, layout, typography, system } from "styled-system";

export const StyledDivider = styled.div`
  ${color}
  ${position}
  ${layout}
  ${typography} 


  ${system({
    transform: {
      property: "transform",
      scale: "transforms",
    },
  })}

${variant({
    variants: {
      waveTopLight: {
        transform: "rotate(180deg)",
        top: 0,
        left: 0,
      },
      curveBottomlight: {
        bottom: 0,
        left: 0,
      },
      waveTopGrey: {
        top: 0,
        left: 0,
      },
      navDivider: {
        transform: "rotate(180deg)",
        bottom: 0,
        left: 0,
      },
      tiltTop: {
        top: 0,
        left: 0,
      },
      tiltBottom: {
        transform: "rotate(180deg)",
        bottom: 0,
        left: 0,
      },
    },
  })}
`;

StyledDivider.defaultProps = {
  position: "absolute",
  width: "100%",
  overflow: "hidden",
  lineHeight: 0,
};

export const StyledDividerSVG = styled.svg`
  ${color}
  ${position}
  ${layout}
  ${typography}
  


  ${variant({
    variants: {
      waveTopLight: {
        width: "calc(225% + 1.3px)",
        height: "205px",
      },

      curveBottomlight: {
        width: "calc(100% + 1.3px)",
        height: "210px",
      },
      waveTopGrey: {
        width: "calc(200% + 1.3px)",
        height: "173px",
      },
      navDivider: {
        width: "calc(150% + 1.3px)",
        height: "200px",
      },
      tiltTop: {
        width: "calc(100% + 1.3px)",
        height: "105px",
        transform: "rotateY(180deg)",
      },
      tiltBottom: {
        width: "calc(100% + 1.3px)",
        height: "105px",
        transform: "rotateY(180deg)",
      },
    },
  })}
`;

StyledDividerSVG.defaultProps = {
  position: "relative",
  display: "block",
};

export const StyledDividerPath = styled.path`
  ${system({
    fill: {
      property: "fill",
      scale: "colors",
    },
  })}

  ${variant({
    variants: {
      waveTopLight: {
        fill: "#f9fbfd",
      },
      curveBottomlight: {
        fill: "#EEF3F9",
      },
      waveTopGrey: {
        fill: "#E8EFF5",
      },
      navDivider: {
        fill: "transparent",
      },
      tiltTop: {
        fill: "bg",
      },
      tiltBottom: {
        fill: "bg",
      },
    },
  })}
`;
