import { Grid as BaseGrid } from "react-styled-flexboxgrid";
import gridConfig, { DIMENSION_NAMES } from "react-styled-flexboxgrid/src/config";
import styled, { css } from "styled-components";
import { space, position, layout } from "styled-system";

const Grid = styled(BaseGrid).attrs(() =>({
 
}))`
  ${space}
  ${position}
  ${layout}
  ${(p) =>
    !p.fluid &&
    css`
      ${DIMENSION_NAMES.map(
        (t) =>
          gridConfig(p).container[t] &&
          gridConfig(p).media[t]`
        width: 100%;
        max-width: ${(p) => gridConfig(p).container[t]}rem;
      `
      )}
    `}
`;

Grid.defaultProps = {
  mb:{
    xl:6,
    lg:6,
    md:5,
    xs:4,
  }
}

export default Grid;
