import React from "react";
import { StyledHamburger } from "./Hamburger.styled";
import { useAtom } from "jotai";
import { modalOpenAtom } from "../../Atoms/atoms";

const Hamburger = () => {
  const [open, setOpen] = useAtom(modalOpenAtom);
  
  return <StyledHamburger toggled={open} toggle={setOpen} label={open ? "open" : "closed"} />;
};

export default Hamburger;
