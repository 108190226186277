import React from "react";

import {
  Nav,
  Grid,
  Col,
  Row,
  Logo,
  Wrapper,
  ContactDetails,
  Hamburger,
} from "../../elements/index";
import Sticky from "../../elements/Sticky/Sticky";

const NewHeader = ({ navMenu }) => {
  const { phone, email } = !!navMenu && navMenu;

  return (
    <Wrapper  variant="nav">
      <Sticky>
        <Grid mb="0" width="100%">
          <Row middle="xs" between="xs">
            <Col lineHeight="0">
              <Logo variant="primary" />
            </Col>
            <Col xs={false} md={true} flexGrowOff>
              <Nav navMenu={navMenu} variant="desktopNav" />
            </Col>
            <Col xs={false} lg={true} flexGrowOff>
              <ContactDetails
                phone={phone}
                email={email}
                phoneIcon="AiOutlineShake"
                emailIcon="AiOutlineMail"
                iconVariant="primary"
                labelVariant="ternary"
              />
            </Col>
            <Col md={false}>
              <Hamburger />
            </Col>
          </Row>
        </Grid>
      </Sticky>
    </Wrapper>
  );
};

export default NewHeader;
