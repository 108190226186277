import { atom } from 'jotai'
import { window } from "browser-monads";



// site settings
export const navAtom  = atom([])
export const navCtaAtom  = atom([])
export const siteTitleAtom = atom()
export const siteLogoAtom = atom()

// UI interaction 
export const navModalOpenAtom = atom(false)
export const NavMenuItemHoverValueAtom = atom()
export const NavMenuItemHoverAtom = atom(false)
export const NavMenuItemTapValueAtom = atom()
export const NavMenuItemTapAtom = atom(false)
export const dropdownSelectedAtom = atom(false)
export const motionImageAtom = atom()
export const NavMenuItemIndexAtom = atom()
export const NavMenuItemPositionAtom = atom(0)
export const NavMenuItemDirectionAtom = atom(0)


//new
export const navMenuItemActiveAtom = atom(false)
export const modalOpenAtom = atom(false)
export const openDelayedAtom = atom(false)

// breakpoints

export const desktopAtom = atom({
  matches: window.innerWidth > 1100 ? true : false
})


// Appointment schedule modal
export const contactOpenAtom = atom(false)


// UI Elements

// button

export const buttonHoverAtom = atom(false)
