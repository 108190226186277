import styled from "styled-components";
import { variant, system, layout, position, color, flexbox } from "styled-system";

export const StyledSticky = styled.div`
  ${position}
  ${layout}
  ${flexbox}
  ${color}

  ${system({
    backdropFilter: {
      property: "backdrop-filter",
    },
    clipPath: {
      property: "clip-path",
    }
  })}

  ${({isFirefox}) => variant({
    variants: {
      navIsTop: {
        backgroundColor: "transparent",
      },
      navIsScrolling: {
        backgroundColor: isFirefox ? "navBgFirefox" : "navBg",
        backdropFilter: isFirefox ? "unset" : "blur(8px)",
        position: "fixed",
        clipPath: "ellipse(95% 97% at 50% 0%)"
      },
    },
  })}
`;

StyledSticky.defaultProps = {
  zIndex: 9,
  width: "100%",
  height: "inherit",
  display: "flex",
  alignItems: "center",
};
