import React, { useState, useRef} from "react";
import { Wrapper, Grid, Row, Col, FooterNav } from "../../elements/index";
import { graphql, StaticQuery } from "gatsby";
import { useInViewport } from 'ahooks';

const Footer = (props) => {
  const { navMenu } = props;
  const footerRef = useRef()
  const [footerInViewport, setFooterInViewport] = useState(false)
  const isFooterInViewport = useInViewport(footerRef)

  return (
    <StaticQuery
      query={graphql`
        query FooterNavQuery {
          allSanityFooterMenu {
            nodes {
              columns {
                _key
                title
                Items {
                  _key
                  externalLink
                  icon
                  label
                  link
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div ref={footerRef}> 
          <Wrapper  variant="ternary" borderTop="1px solid hsl(0deg 0% 100% / 15%)">
            <Grid mb="0">
              <Row around="xs" between="md" variant="footer">
                <FooterNav navMenu={navMenu} footerMenu={data.allSanityFooterMenu.nodes[0]} />
              </Row>
            </Grid>
          </Wrapper>
        </div>
      )}
    />
  );
};

export default Footer;
