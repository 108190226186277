export const slideDown = {
  start: {
    y: 0,
  },
  hover: {
    y: [5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0],

    transition: {
      type: "tween",
      duration: 7,
      repeat: "Infinity",
    },
  },
  end: {
    y: 0,
  },
};

export const slideRight = {
  start: {
    x: 0,
    y:0,
    opacity:1,
  },
  hover: {
    x: [5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0],

    transition: {
      type: "tween",
      duration: 7,
      repeat: "Infinity",
      opacity:0
    },
  },
  end: {
    x: 0,
    opacity:1
  },
};

export const ring = {
  start: {
    rotate: 0,
  },
  hover: {
    rotate: [
      30,
      0,
      -30,
      0,
      30,
      0,
      -30,
      0,
      30,
      0,
      -30,
      0,
      30,
      0,
      -30,
      0,
      30,
      0,
      -30,
      0,
      30,
      0,
      -30,
      0,
    ],
    transition: {
      type: "tween",
      duration: 1.5,
      repeat: "Infinity",
    },
  },
  end: {
    rotate: 0,
  },
};
