import styled from "styled-components";
import { motion } from "framer-motion";
import { position, layout, color, flexbox, space } from "styled-system";

export const StyledMobileNav = styled(motion.div)(
  position,
  layout,
  flexbox,
  color,
  space,

  {
    minHeight: "inherit",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  }
);

StyledMobileNav.defaultProps = {
  p: 5,
};

export const StyledMobileNavWrapper = styled.div(
  layout,
  space,
  flexbox,

  {
    maxHeight: "800px",
    minHeight:"500px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flexGrow: 1,
    flexShrink:0
  }
);
