import styled from "styled-components";
import { variant, system, color, space, typography, layout, flexbox } from "styled-system";
import { motion } from "framer-motion";

export const StyledContactDetails = styled.div`
  ${layout}
  ${flexbox}
`;

StyledContactDetails.defaultProps = {
  display: "flex",
  flexDirection: "column",
};

export const StyledContactLabel = styled(motion.a).attrs(() => ({
  initial: "start",
  whileHover: "hover",
  animate: "end",
}))(layout, flexbox, typography, space, color);
StyledContactLabel.defaultProps = {
  display: "flex",
  alignItems: "center",
  fontFamily: "subtitle",
  fontSize: "large",
  fontWeight: 5,
};

export const StyledContactDetail = styled.span(
  variant({
    variants: {
      primary: {
        color: "primaryCdFontColor",
      },
      secondary: {
        color: "secondaryCdFontColor",
      },

      ternary: {
        color: "ternaryCdFontColor",
      },
    },
  })
);

export const StyledSocial = styled.div(
  flexbox,
  space,
  layout,
  color,

  {
    display: "flex",
  }
);
