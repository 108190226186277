import styled from "styled-components";
import { motion } from "framer-motion";
import { slideDown, slideRight, ring } from "./Icon.motion";
import { variant, color, space, layout, typography } from 'styled-system'


const animations = {
  slideDown: slideDown,
  slideRight: slideRight,
  ring: ring,
};

const selectAnimation = (type) => animations[type];

export const StyledIcon = styled(motion.span).attrs(({ animation }) => ({
  variants: selectAnimation(animation),
}))`
  display: flex;
  cursor: pointer;
  margin: ${({ iconPosition }) => (iconPosition ? "0 0 0 0.5rem" : "0 .5rem 0 0")};
  order: ${({ iconPosition }) => (iconPosition ? 2 : 0)};

  ${color}
  ${space}
  ${typography}

  ${variant({
    variants: {
      primary: {
        color: 'primaryIcon'
      },
      secondary: {
        color: 'secondaryIcon'
      },
      ternary: {
        color: 'ternaryIcon',
        "button:hover &":{
          color: 'ternaryIconHover'
        }
      }
    }
  })}


`;
