import styled from "styled-components";
import { motion } from "framer-motion";
import { typography, space } from "styled-system";

export const StyledTypeWriter = styled.span.attrs(() => ({
  ml: {
    lg: 2,
    md: 1,
    sm: 1,
    xs: 1,
  },
}))`
  ${space}
  ${typography}
`;

StyledTypeWriter.defaultProps = {
  fontFamily: "subtitle",
}

export const StyledTyping = styled(motion.div)
  .withConfig({
    shouldForwardProp: (prop) => !["fontSize", "fontFamily"].includes(prop),
  })
  .attrs(() => ({
    fontSize: {
      lg: "h3",
      md: "h3",
      sm: "h5",
      xs: "large"
    },
    fontFamily: "subtitle",
  }))`
  ${typography}
  display: flex;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.typeWriterCursor};
  line-height: 1.1;


  & .Typewriter__wrapper {
    color: ${({ theme }) => theme.typeWriterFont};
    font-weight: 600;
  }
`;
