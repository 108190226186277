import styled from "styled-components";
import { variant, flexbox, layout, space, color, shadow, border, boxShadow } from "styled-system";

export const StyledLightBox = styled.div`
  ${flexbox}
  ${layout}
  ${space}
  grid-template-columns: auto auto auto;
  gap: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    grid-template-columns: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: auto auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-template-columns: auto auto auto;
    
  }
`;

StyledLightBox.defaultProps = {
  display: "grid",
};

export const StyledLightBoxImage = styled.img`
  margin-bottom: 2%; /* (100-32*3)/2 */
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  
  ${shadow}
  ${border}
`;

StyledLightBoxImage.defaultProps = {
  borderRadius: "16px",
  boxShadow: "primary",
};
