import React from "react";
import * as AiIcons from "react-icons/ai";
import { StyledIcon } from "./icon.styled";


const Icon = (props) => {
  const aiIcon = AiIcons[props.icon];
  const {variant = "primary", iconSize} = props;
  return <StyledIcon variant={variant} fontSize={iconSize} {...props}>{React.createElement(aiIcon)}</StyledIcon>;
};

export default Icon;
