import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { Img } from "../";
import { StyledLightBox, StyledLightBoxImage } from "./LightBox.Styled";

const options = {
  settings: {
    autoplaySpeed: 3000,
    boxShadow: "none",
    disableKeyboardControls: false,
    disablePanzoom: false,
    disableWheelControls: false,
    hideControlsAfter: 3000,
    lightboxTransitionSpeed: 0.3,
    lightboxTransitionTimingFunction: "linear",
    overlayColor: "rgba(30, 30, 30, 0.9)",
    slideAnimationType: "fade",
    slideSpringValues: [300, 50],
    slideTransitionSpeed: 0.6,
    slideTransitionTimingFunction: "linear",
    usingPreact: false,
  },
  buttons: {
    showDownloadButton: false,
  },
  progressBar: {
    backgroundColor: "#f2f2f2",
    fillColor: "#000000",
    height: "3px",
    showProgressBar: true,
  },
};

const LightBox = ({ images }) => {
  return (
    <>
      {images && (
        <SRLWrapper options={options}>
          <StyledLightBox mb="4">
            {images.map((image) => {
              return (
                <>
                  <StyledLightBoxImage
                    src={image?.image?.asset?.url}
                    key={image?.image?.asset?.assetId}
                    alt={image?.image?.alt}
                  />
                </>
              );
            })}
          </StyledLightBox>
        </SRLWrapper>
      )}
    </>
  );
};

export default LightBox;
