import { motion } from "framer-motion";
import styled from "styled-components";
import { typography, space, color, variant } from "styled-system";

const selectHeadingStyle = (type) => {
  const headingStyles = {
    h1: {
      lg: "h1",
      md: "h2",
      sm: "h3",
      xs: "h4",
    },
    h2: {
      lg: "h2",
      md: "h3",
      sm: "h4",
      xs: "h5",
    },
    h3: {
      lg: "h3",
      md: "h3",
      sm: "h5",
      xs: "large",
    },
    h4: {
      lg: "h4",
      md: "h5",
      xs: "h5",
    },
    h5: {
      lg: "h5",
      xs: "h5",
    },
  };

  return headingStyles[type];
};

const selectHeadingSpacing = (type) => {
  const headingSpacing = {
    h1: {
      lg: 4,
      md: 3,
      xs: 3,
    },
    h2: {
      lg: 4,
      xs: 3,
    },
    h3: {
      md: 4,
      xs: 3,
    },
    h4: {
      lg: 3,
      xs: 2,
    },
    h5: {
      lg: 3,
      xs: 2,
    },
  };

  return headingSpacing[type];
};

export const StyledHeadline = styled(motion.h1).attrs(({ type, mb }) => ({
  fontSize: selectHeadingStyle(type),
  mb: mb ? mb : selectHeadingSpacing(type),


}))`
  ${space}
  ${typography}
  ${color}
  ${variant({
    variants: {
      primary: {
        color: 'primaryHeading',
        fontWeight: "700"
      },
      secondary: {
        color: "secondaryHeading",
        fontWeight: "700",
      },
      ternary : {
        color: "ternaryHeading",
        fontWeight: "700",
      }
    }
  })}

`;

StyledHeadline.defaultProps = {
 
}