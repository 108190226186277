import { Col as BaseCol } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { motion } from "framer-motion";
import { flexbox, space, position, typography, layout, variant } from "styled-system";

const slideRightVariants = {
  start: {
    opacity: 0,
    x: -600,
  },
  end: {
    x: 0,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 1.5,
    },
  },
};

// const animations = {
//   slideRightVariants: slideRightVariants,

//   inline: {
//     flexDirection: "row",
//   },
// };

// const selectAnimation = animations["slideRightVariants"];

const Col = styled(BaseCol).attrs(({ slideRight }) => ({
  as: motion.div,
  variants: slideRight && slideRightVariants,
  initial: "start",
  animate: "end",
}))`
  ${flexbox};
  ${space};
  ${position};
  ${typography}
  ${layout}

@media(min-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${variant({
      variants: {
        footer: {
          order: "-1"
        },
        diensten: {
          flexShrink: "1"
        }
      },
    })}
  }

  order: ${({ ColReverse }) => (ColReverse ? -1 : 0)};
  display: ${({ flex }) => (flex ? "flex" : "block")};
  flex-direction: ${({ flex }) => flex && "column"};
  flex-grow: ${({ flexGrowOff }) => flexGrowOff && 0} !important;
`;

Col.defaultProps = {
  mb: {
    xs: 0,
    md: 0,
  },
};

export default Col;
