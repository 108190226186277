import React from "react";
import { StyledSticky } from "./Sticky.styled";
import { useScroll } from "ahooks";
import { isFirefox } from "react-device-detect";

const Sticky = ({ children }) => {
  const scrollPosition = useScroll();

  return (
    <>
      <StyledSticky
        isFirefox={isFirefox}
        variant={scrollPosition.top !== 0 ? "navIsScrolling" : "navIsTop"}
      >
        {children}
      </StyledSticky>
    </>
  );
};

export default Sticky;
