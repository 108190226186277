import React, { useEffect } from "react";
import NewHeader from "./NewHeader/NewHeader";
import Footer from "./Footer/Footer";
import styled from "styled-components";
import { useAtom } from "jotai";
import { modalOpenAtom, siteLogoAtom, siteTitleAtom } from "../Atoms/atoms";
import { MobileNav } from "../elements/index";
import { AnimatePresence } from "framer-motion";
import { useResponsive } from "ahooks";
import { graphql, StaticQuery } from "gatsby";
import SimpleReactLightbox from "simple-react-lightbox";

const Site = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

export default function LayoutTV(props) {
  const { children, siteTitle, siteLogo, data } = props;

  const [, setSiteLogo] = useAtom(siteLogoAtom);
  const [, setSiteTitle] = useAtom(siteTitleAtom);
  const [open, setOpen] = useAtom(modalOpenAtom);

  const responsive = useResponsive();
  useEffect(() => {
    if (responsive.md) {
      setOpen(false);
    }
  }, [responsive]);

  useEffect(() => {
    setSiteLogo(siteLogo);
    setSiteTitle(siteTitle);
  }, [setSiteLogo, siteLogo, setSiteTitle, siteTitle]);

  return (
    <StaticQuery
      query={graphql`
        query navQuery {
          allSanityNavigationMenu {
            nodes {
              items {
                route
                title
                _key
                landingPageRoute {
                  _key
                  slug {
                    current
                  }
                  _type
                  _id
                }
              }
              phone
              email
            }
          }
        }
      `}
      render={(data) => {
        const navMenu = data.allSanityNavigationMenu.nodes[0];
        const { phone, email } = navMenu;

        return (
          <Site>
            <SimpleReactLightbox>
              <AnimatePresence>
                {open && (
                  <MobileNav
                    open={open}
                    modalVariant="sidebar"
                    phone={phone}
                    email={email}
                    phoneIcon="AiOutlineShake"
                    emailIcon="AiOutlineMail"
                    iconVariant="secondary"
                    labelVariant="secondary"
                    facebook="https://www.facebook.com/Tuinen-Veulemans-769864303408085"
                    instagram="https://www.instagram.com/tuinenveulemans/"
                    facebookIcon="AiFillFacebook"
                    instagramIcon="AiFillInstagram"
                    iconVariant="secondary"
                    labelVariant="secondary"
                    social
                    iconSize="1.5rem"
                    navMenu={navMenu}
                    setOpen={setOpen}
                  />
                )}
              </AnimatePresence>
              <div className="site__top">
                <NewHeader navMenu={navMenu} />
                <section className="site__main">{children}</section>
              </div>
              <Footer navMenu={navMenu} />
            </SimpleReactLightbox>
          </Site>
        );
      }}
    />
  );
}
