import React from "react";
import clientConfig from "../../../client-config";
import serializers from "../../components/serializers";
import { StyledText } from "./Text.styled";

const Text = (props) => {
  const { blocks, variant = "ternary" } = props;
  return (
    <>
      {blocks && (
        <StyledText
          variant={variant}
          renderContainerOnSingleChild
          blocks={blocks}
          serializers={serializers}
          {...clientConfig.sanity}
          {...props}
        />
      )}
    </>
  );
};

export default Text;
