import styled from "styled-components";
import { system, layout, variant, position } from "styled-system";
import { Link } from "gatsby";

export const StyledLogoLink = styled(Link)`
@media(min-width: ${({theme}) => theme.breakpoints[0]}){
  width: ${({variant}) => variant === "primary" && "160px"};
}
@media(min-width: ${({theme}) => theme.breakpoints[1]}){
  width: ${({variant}) => variant === "primary" && "250px"};
}

@media(min-width: ${({theme}) => theme.breakpoints[1]}){
  width: ${({variant}) => variant === "primary" && "300px"};
}

`

export const StyledLogo = styled.svg`
  ${layout}
  ${position}
  ${system({
    fill: {
      property: "fill",
      scale: "colors",
    },
  })}

${variant({
  variants: {
    primary: {
      fill: "logo",
      width: "100%",
      zIndex: 9999,
    
    },
    secondary: {
      fill: "secondaryLogo",
    }
  }
})}

`;



