import React from "react";
import { useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { Text } from "../";
import {
  FaqQuestion,
  FaqAnswer,
  FaqItem,
  FaqItems,
  FaqHeader,
  IconPlusWrapper,
  IconMinWrapper,
} from "./AccordionFAQ.styled";
import { AiOutlinePlus as Plus, AiOutlineLine as Min } from "react-icons/ai";

export default function AccordionFaq({ items }) {
  return (
    <AnimateSharedLayout>
      <FaqItems variant="primary" layout>
        {items.map((item) => (
          <Item key={item._key} content={item} />
        ))}
      </FaqItems>
    </AnimateSharedLayout>
  );
}

function Item({ content }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <FaqItem layout onClick={toggleOpen}>
      <FaqHeader>
        <FaqQuestion isOpen={isOpen} variant="secondary" layout>{content.question} </FaqQuestion>
        <motion.span layout>
          <AnimatePresence>
            {isOpen ? (
              <IconMinWrapper layout>
                <Min />
              </IconMinWrapper>
            ) : (
              <IconPlusWrapper isOpen={isOpen} layout>
                <Plus />
              </IconPlusWrapper>
            )}
          </AnimatePresence>
        </motion.span>
      </FaqHeader>
      <AnimatePresence>
        {isOpen && <Content isOpen={isOpen} faqAnswer={content.answer} />}
      </AnimatePresence>
    </FaqItem>
  );
}

function Content({ faqAnswer, isOpen }) {
  return (
    <FaqAnswer
      layout
      isOpen={isOpen}
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: 1,
        height: "auto",
        transition: {
          type: "tween",
          
 
        }
      }}
      exit={{ opacity: 0, height: 0 }}
    >
      <Text variant="secondary" blocks={faqAnswer} />
    </FaqAnswer>
  );
}
