import React, { useRef } from "react";
import { StyledModal } from "./Modal.styled";

const Modal = (props) => {
  const { children, variant, setOpen } = props;



  return (
    <StyledModal  variant={variant}>
      {children}
    </StyledModal>
  );
};

export default Modal;
