import styled from "styled-components";
import { motion } from "framer-motion";
import {
  system,
  variant,
  space,
  color,
  layout,
  typography,
  flexbox,
  position,
} from "styled-system";


export const StyledNavItems = styled(motion.ul)(
  space,
  color,
  layout,
  typography,
  flexbox,

  system({
    textTransform: {
      property: "textTransform",
    },
  }),

  system({
    content: true,
  }),

  variant({
    variants: {
      mobileNav: {
        flexDirection: "column",
        "& .active-link-parent:after": {
          backgroundColor: "#fff",
          content: "5454545454545454",
        },

        "& li:after": {
          backgroundColor: "#fff",
        },
      },

      desktopNav: {
        "& .active-link-parent:after": {
          backgroundColor: "navHoverEffect",
        },
      },

      footerNav: {
        flexDirection: "column"
      }
    },
  }),

  {
    "& .active-link-parent:after": {
      position: "absolute",

      width: "100%",
      top: "30px",
      left: 0,
      bottom: 0,
      height: "3px",
    },
  }
);

StyledNavItems.defaultProps = {
  display: {
    xs: "flex",
  },
  fontFamily: "subtitle",
  fontWeight: 5,
};

export const StyledNavItem = styled(motion.li)`
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${position}

  ${system({
    content: true,
  })}

  ${variant({
      variants: {
        desktopNav: {},
        mobileNav: {
          mb:4,
          "& a":{
            color: "mobileNavFont"
          }
        },
        footerNav: {
          "& a":{
            color: "footerColorTernary"
          }
        }
      },
    })}

  &:after {
    position: absolute;
    transition: 0.6s;
    content: "";
    width: 0;
    top: 30px;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: ${({ theme }) => theme.colors.navHoverEffect};
    right: 0;
    left: auto;
  }

  &:hover {
    cursor: pointer;

    a {
      font-weight: bold;
    }
    &:after {
      width: 100%;
      left: 0;
    }
  }
`;

StyledNavItem.defaultProps = {
  position: "relative",
  mr: {
    xs: 4,
  },
  mb: 0,

};
