import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  *, *::after, *::before {
    box-sizing: border-box;
  }

  html {
    overflow-y: scroll;
  }

  html, body{
    /* overflow-y: ${({ open }) => (open ? "hidden" : "initial")}; */
    overflow-x:hidden; 
  }

  p a {
    color: #28B17D;
    font-weight: 700;
  }


/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999999999999999999999999999999999999999999999999999999999999999s;
} */


  body{
 
    background: ${({ theme }) => theme.colors.bg};
    margin-right: 0 !important;  
  
    /* overflow: ${({ open }) => (open ? "hidden" : "initial")}; */
    /* overflow-x:hidden; */
  }

  

  button{
    background:transparent;
  }


  a {
    display:inline-block;
    text-decoration:none;
    color: ${({ theme }) => theme.colors.link};
  }

  a:hover {
    text-decoration:none;
  }

  ul {
    margin:0;
    padding:0;
    list-style:none;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  
`;
export default GlobalStyles;
