import { Row as BaseRow } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { variant, layout, space, position, flexbox, color, typography } from "styled-system";

const Row = styled(BaseRow)`
  ${layout}
  ${space}
 ${position}
 ${flexbox}
 ${color}
 ${typography}

 @media(max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${variant({
      variants: {
        footer: {
          textAlign: "center",
          "& div": {
            mb: 5,
          },
          "& div:first-child": {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        },
      },
    })}
  }
`;

export default Row;
