import styled from "styled-components";
import { color, space, layout, position, background, border } from "styled-system";
import { variant } from "styled-system";

export const StyledWrapper = styled.div`
  ${color}
  ${space}
  ${layout}
  ${position}
  ${background}
  ${border}

  ${variant({
    variants: {
      primary: {
        bg: "primaryBG",
      },
      secondary: {
        bg: "secondaryBG",
      },

      ternary: {
        bg: "ternaryBG",
      },
      none: {
        bg: "none",
      },
      nav: {
        mb: [0, 3, , 3],
        pt: [, 0],
        pb: "0",
        height: [, "100px", , "150px"],
      },
    },
  })}
`;

StyledWrapper.defaultProps = {
  position: "relative",
  pt: {
    xs: 3,
    md: 5,
  },
  pb: {
    xs: 3,
    md: 5,
  },
};
