import React from "react";
import {
  StyledContactDetails,
  StyledContactLabel,
  StyledContactDetail,
  StyledSocial,
} from "./ContactDetails.styled";
import { Icon, Headline } from "../index";

const ContactDetails = (props) => {
  const {
    phone,
    email,
    facebook,
    instagram,
    facebookIcon,
    instagramIcon,
    phoneIcon,
    emailIcon,
    iconVariant,
    labelVariant,
    social,
    iconSize,
  } = props;

  return (
    <>
      <StyledContactDetails>
        {phone && (
          <StyledContactLabel href={`tel:${phone}`} mb="2">
            <Icon variant={iconVariant} icon={phoneIcon} iconSize={iconSize} animation="ring" />
            <StyledContactDetail variant={labelVariant}>{phone}</StyledContactDetail>
          </StyledContactLabel>
        )}

        {email && (
          <StyledContactLabel href={`mailto:${email}`}>
            <Icon variant={iconVariant} icon={emailIcon} iconSize={iconSize} animation="ring" />
            <StyledContactDetail variant={labelVariant}>{email}</StyledContactDetail>
          </StyledContactLabel>
        )}

        {social && (
          <div>
            <Headline mb="3" variant="secondary" h={3} type="h5" heading="Volg ons op" />
            <StyledSocial>
              {facebook && (
                <StyledContactLabel href={`${facebook}`}>
                  <Icon
                    iconSize={iconSize}
                    variant={iconVariant}
                    icon={facebookIcon}
                    animation="ring"
                  />
                </StyledContactLabel>
              )}

              {instagram && (
                <StyledContactLabel href={`${instagram}`}>
                  <Icon
                    iconSize={iconSize}
                    variant={iconVariant}
                    icon={instagramIcon}
                    animation="ring"
                  />
                </StyledContactLabel>
              )}
            </StyledSocial>
          </div>
        )}
      </StyledContactDetails>
    </>
  );
};

export default ContactDetails;
